@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2") format("woff2"), /* Modern format */ url("./fonts/Poppins-Regular.woff") format("woff"); /* Fallback */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.woff2") format("woff2"), /* Modern format */ url("./fonts/Poppins-Medium.woff") format("woff"); /* Fallback */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.woff2") format("woff2"), /* Modern format */ url("./fonts/Poppins-Bold.woff") format("woff"); /* Fallback */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif !important;
}

.apexcharts-toolbar {
  display: none !important;
}

/* Carousel css */
.slider-wrapper .slide {
  transform: scaleY(0.8);
  transition: transform 0.2s;
}

.slider-wrapper .selected {
  transform: scaleY(1) !important;
  transition: transform 0.2s;
  animation: ease-in-out;
}

/* Example styles for when the navbar is scrolled */
.bg-transparent {
  background-color: rgba(255, 255, 255, 0.75); /* White with transparency */
}

.backdrop-blur-md {
  backdrop-filter: blur(10px);
}

/* Ensure the navbar is on top of all other content */
.z-10 {
  z-index: 10;
}
